/** @jsxImportSource @emotion/react */
import { useUser } from "@clerk/clerk-react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Image } from "@mantine/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Styled = {
  Headline: styled.div({
    fontFamily: '"Inter"',
    fontSize: "90px",
    fontStyle: "normal",
    fontWeight: "900",
    letterSpacing: "-.02em",
    color: "rgba(255, 255, 255, 0.9)",
    textShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    marginTop: 150,
    marginBottom: 30,
    lineHeight: 1,
    zIndex: 2, // Ensure text is above bubbles
  }),
  HeadlineSubText: styled.p({
    marginTop: 0,
    fontFamily: '"Inter", "sans-serif"',
    fontSize: "18px",
    fontStyle: "normal",
    color: "rgba(255, 255, 255, 0.9)",
    textShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    maxWidth: 600,
    textAlign: "center",
    margin: "0 auto",
    marginBottom: 40,
    zIndex: 2, // Ensure subtext is above bubbles
  }),
  ImageContainer: styled.div({
    marginTop: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2, // Ensure image is above bubbles
    position: "relative",
  }),
  Image: styled.div({
    width: "70%",
    marginTop: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "15px", // Rounded corners
    overflow: "hidden", // Ensure the corners are actually rounded
    zIndex: 2, // Ensure the image is above bubbles
    position: "relative", // Make sure it's positioned relative for overlay
    boxShadow: "0 0 0 0.5px lightgray", // Apply the border as a box-shadow

    // Add a gradient mask to fade the image into the background
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(to bottom, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 1) 100%)",
      pointerEvents: "none", // Allow interaction with the image below
      zIndex: 3, // Ensure overlay is above the image
    },

    // Create a gradient mask for both the image and its border
    maskImage:
      "linear-gradient(to bottom, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%)",

    // Media query for small screens
    "@media (max-width: 768px)": {
      width: "100%",
    },
    "@media (max-width: 480px)": {
      width: "100%",
    },
  }),
};

export const Headline = () => {
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    const maxDots = 300; // Maximum number of dots allowed
    let currentDots = 0;

    const createDot = () => {
      const container = document.querySelector(".dots-container");
      if (!container || currentDots >= maxDots) return;

      const dot = document.createElement("div");
      dot.classList.add("dot");
      const size = Math.random() * 2 + 1;
      dot.style.width = `${size}px`;
      dot.style.height = `${size}px`;

      dot.style.top = `${Math.random() * 100}%`;
      dot.style.left = `${Math.random() * 100}%`;
      dot.style.animationDuration = `${Math.random() * 5 + 10}s`;

      // Remove the dot once the animation ends and update dot count
      dot.addEventListener("animationend", () => {
        container.removeChild(dot);
        currentDots--;
      });

      container.appendChild(dot);
      currentDots++;
    };

    // Create initial batch of dots
    for (let i = 0; i < 150; i++) {
      createDot();
    }

    // Add new dots continuously up to the maximum number
    const interval = setInterval(() => {
      if (currentDots < maxDots) {
        createDot();
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div css={gradientBackground} className="dots-container">
      <Styled.Headline>
        Maximize The Magic, <br />
        Minimize the Cost
      </Styled.Headline>
      <Styled.HeadlineSubText>
        A powerful tool that searches all available dates in your available
        travel window to uncover the best prices for all the Disney World
        resorts.
      </Styled.HeadlineSubText>
      <Button
        color="rgba(255, 255, 255, 1)"
        styles={{
          label: { color: "black" },
          inner: { fontSize: 20 },
        }}
        onClick={() =>
          user.isSignedIn ? navigate("/newHotelQuery") : navigate("/sign-up")
        }
        variant="filled"
        size="lg"
        radius="md"
      >
        Get Started
      </Button>

      <Styled.ImageContainer>
        <Styled.Image>
          <Image
            style={{ borderRadius: "inherit" }}
            src="/images/FullPageExample.png"
          />
        </Styled.Image>
      </Styled.ImageContainer>
    </div>
  );
};

const floatDot = keyframes`
  0% {
    transform: translateY(0);  
    opacity: 0.8;
  }
  100% {
    transform: translateY(-110vh); 
    opacity: 0;
  }
`;

const gradientBackground = css`
  width: 100vw;
  height: 150vh;
  background: linear-gradient(
    to bottom,
    #1e3a8a,
    #3b82f6,
    #60a5fa 60%,
    rgba(255, 255, 255, 0.3) 80%,
    rgba(255, 255, 255, 0.6) 90%,
    white 100%
  );
  overflow: hidden;
  position: relative;
  z-index: 1; // Ensure background and bubbles are below other content

  .dot {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    opacity: 0.7;
    animation: ${floatDot} linear infinite;
    z-index: 1; // Ensure dots are below content
  }
`;
