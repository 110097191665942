import { useAuth } from "@clerk/clerk-react";
import styled from "@emotion/styled";
import { AppShell, AppShellMain, Button, Space } from "@mantine/core";
import axios from "axios";
import { TiStarFullOutline } from "react-icons/ti";

const Styled = {
  Background: styled.div({
    backgroundColor: "#f0f0f0",
    minHeight: "100vh",
    fontFamily: "'Montserrat', sans-serif",
  }),
  HeaderText: styled.div({
    fontSize: 40,
    fontWeight: 700,
  }),
  HeaderDescriptionText: styled.div({
    fontSize: 20,
    fontWeight: 700,
  }),
  Container: styled.div({
    display: "flex",
    marginTop: 50,
    flexWrap: "wrap",
    gap: 30,
    justifyContent: "center",
    alignItems: "center",
  }),
  LightCard: styled.div({
    backgroundColor: "white",
    borderRadius: 15,
    minWidth: 300,
  }),
  DarkCard: styled.div({
    backgroundColor: "#0A0A23",
    borderRadius: 15,
    minHeight: 400,
    minWidth: 300,
    color: "white",
  }),
  TierText: styled.div({
    fontSize: 25,
    fontWeight: 700,
  }),
  PriceText: styled.div({
    fontSize: 20,
    fontWeight: 700,
  }),
  Divider: styled.div({
    marginTop: 30,
    marginBottom: 30,
    marginLeft: 20,
    marginRight: 20,
    height: 1,
    backgroundColor: "lightgray",
  }),
  ListIcon: styled.div({
    color: "#ADFF2F",
  }),
  ListItem: styled.div({
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
  }),
  GetStartedButton: styled.div({
    display: "flex",
    marginTop: 20,
    paddingBottom: 20,
    marginLeft: 20,
    marginRight: 20,
  }),
  DarkGetStartedButton: styled.div({
    marginTop: 150,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
  }),
  IconText: styled.div({
    display: "flex",
    alignItems: "center",
    gap: 15,
  }),
};

export function Hotels() {
  const { getToken } = useAuth();

  const sendPaymentRedirect = async (priceId: string) => {
    let queryString = `https://node.kylelbrown.com/hotels/create-checkout-session?priceId=${priceId}`;

    const token = await getToken();
    const result: any = await axios.get(queryString, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.href = result.data.url;
    return;
  };

  return (
    <AppShell.Main style={{ backgroundColor: "#f0f0f0" }}>
      <Styled.Background>
        <Styled.HeaderText>Package Tiers</Styled.HeaderText>
        <Styled.HeaderDescriptionText>
          Choose the package that suits you best.
        </Styled.HeaderDescriptionText>
        <Styled.Container>
          <Styled.LightCard>
            <Space h="xs" />
            <Styled.TierText>Starter Package</Styled.TierText>
            <Styled.PriceText>$1</Styled.PriceText>

            <Styled.Divider />

            <Styled.ListItem>
              <Styled.IconText>
                <TiStarFullOutline color="#ADFF2F" />
                Up to 3 queries
              </Styled.IconText>
            </Styled.ListItem>
            <Space h="xs" />
            <Styled.ListItem>
              <Styled.IconText>
                <TiStarFullOutline color="#ADFF2F" />
                2-month-long trip windows
              </Styled.IconText>
            </Styled.ListItem>

            <Styled.GetStartedButton>
              <Button
                onClick={() =>
                  sendPaymentRedirect("price_1PG3pfRtiqYIw0xsObCMWXYR")
                }
                color="blue"
                fullWidth
                mt="md"
                radius="md"
              >
                Get Started
              </Button>
            </Styled.GetStartedButton>
          </Styled.LightCard>
          <Styled.DarkCard>
            <Space h="xs" />
            <Styled.TierText>Enthusiast Package</Styled.TierText>
            <Styled.PriceText>$5</Styled.PriceText>

            <Styled.Divider />

            <Styled.ListItem>
              <Styled.IconText>
                <TiStarFullOutline color="#ADFF2F" />
                Up to 20 queries
              </Styled.IconText>
            </Styled.ListItem>
            <Space h="xs" />
            <Styled.ListItem>
              <Styled.IconText>
                <TiStarFullOutline color="#ADFF2F" />
                4-month-long trip windows
              </Styled.IconText>
            </Styled.ListItem>

            <Styled.DarkGetStartedButton>
              <Button
                onClick={() =>
                  sendPaymentRedirect("price_1PG3pdRtiqYIw0xsq9qUoO4v")
                }
                color="blue"
                fullWidth
                mt="md"
                radius="md"
              >
                Get Started
              </Button>
            </Styled.DarkGetStartedButton>
          </Styled.DarkCard>
          <Styled.LightCard>
            <Space h="xs" />
            <Styled.TierText>Enterprise Package</Styled.TierText>
            <Styled.PriceText>$25</Styled.PriceText>

            <Styled.Divider />

            <Styled.ListItem>
              <Styled.IconText>
                <TiStarFullOutline color="#ADFF2F" />
                Up to 100 queries
              </Styled.IconText>
            </Styled.ListItem>
            <Space h="xs" />
            <Styled.ListItem>
              <Styled.IconText>
                <TiStarFullOutline color="#ADFF2F" />
                6-month-long trip windows
              </Styled.IconText>
            </Styled.ListItem>

            <Styled.GetStartedButton>
              <Button
                onClick={() =>
                  sendPaymentRedirect("price_1PG3paRtiqYIw0xstOCubxYP")
                }
                color="blue"
                fullWidth
                mt="md"
                radius="md"
              >
                Get Started
              </Button>
            </Styled.GetStartedButton>
          </Styled.LightCard>
        </Styled.Container>
      </Styled.Background>
    </AppShell.Main>
  );
}
