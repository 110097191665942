import styled from "@emotion/styled";
import { Grid } from "@mantine/core";

const Styled = {
  Terms: styled.p({}),
};

export function Privacy() {
  return (
    <Grid
      align="center"
      style={{
        paddingTop: 50,
        paddingBottom: 20,
        backgroundColor: "white",
      }}
    >
      <Grid.Col style={{}} span={{ base: 12, md: 4 }}></Grid.Col>
      <Grid.Col style={{}} span={{ base: 12, md: 4 }}>
        <Styled.Terms>
          <h1>Privacy Policy</h1>
          <p>
            <strong>Last Updated: 05/19/2024</strong>
          </p>

          <p>
            Magical Date Finder ("we", "us", "our") is committed to protecting
            your privacy. This Privacy Policy explains how we collect, use, and
            share information about you when you use our service.
          </p>

          <h2>1. Information We Collect</h2>
          <p>
            <strong>Personal Information:</strong> When you register for an
            account, we collect personal information such as your name, email
            address, and payment information.
          </p>
          <p>
            <strong>Usage Data:</strong> We collect information about your
            interactions with our service, such as your IP address, browser
            type, and access times.
          </p>

          <h2>2. How We Use Your Information</h2>
          <p>
            <strong>To Provide the Service:</strong> We use your personal
            information to operate and maintain our service.
          </p>
          <p>
            <strong>To Improve Our Service:</strong> We analyze usage data to
            improve our service.
          </p>
          <p>
            <strong>To Communicate with You:</strong> We may use your
            information to send you updates, newsletters, and other
            communications.
          </p>

          <h2>3. Sharing Your Information</h2>
          <p>
            <strong>Service Providers:</strong> We may share your information
            with third-party service providers who assist us in operating our
            service.
          </p>
          <p>
            <strong>Legal Requirements:</strong> We may disclose your
            information if required by law or to protect our rights.
          </p>

          <h2>4. Data Security</h2>
          <p>
            We implement security measures to protect your information. However,
            no method of transmission over the internet or electronic storage is
            100% secure.
          </p>

          <h2>5. Data Retention</h2>
          <p>
            We retain your personal information for as long as necessary to
            provide our service and as required by law.
          </p>

          <h2>6. Your Rights</h2>
          <p>
            <strong>Access and Update:</strong> You have the right to access and
            update your personal information.
          </p>
          <p>
            <strong>Delete:</strong> You may request the deletion of your
            personal information, subject to certain legal obligations.
          </p>

          <h2>7. Changes to Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We encourage
            you to review this policy periodically.
          </p>

          <h2>8. Contact Information</h2>
          <p>
            For any questions regarding this Privacy Policy, please contact us
            at kyle@brownsmediagroup.com.
          </p>
        </Styled.Terms>
      </Grid.Col>
      <Grid.Col style={{}} span={{ base: 12, md: 4 }}></Grid.Col>
    </Grid>
  );
}
