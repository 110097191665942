/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Image } from "@mantine/core";
import { useEffect } from "react";

const Styled = {
  Container: styled.div({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 150,
  }),
  Section: styled.div({
    backgroundColor: "rgb(1, 6, 10)",
    borderRadius: 64,
    minHeight: "300px",
    width: "80%",
    display: "flex",
  }),
  Text: styled.div({
    color: "rgb(80, 99, 109)",
    fontSize: 40,
    fontFamily: "Inter",
    fontWeight: 900,
    letterSpacing: "-.02em",
    padding: 40,
    textAlign: "left",
  }),
};

export const BlackCallout = () => {
  return (
    <Styled.Container>
      <Styled.Section>
        <Styled.Text>
          Have you ever found your self wanting to stay at a specific resort
          only to see it is twice as expensive as you thought for the days you
          selected? Wouldn't it be great if there was a system that would go and
          find what would be the cheapest travel days for all Disney World
          Hotels, without you have to do 100 searches your self?
        </Styled.Text>
      </Styled.Section>
    </Styled.Container>
  );
};
