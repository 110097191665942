import {
  LoadingOverlay,
  Container,
  Table,
  Title,
  AppShell,
  Progress,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useAuth, useUser } from "@clerk/clerk-react";
import { useViewportSize } from "@mantine/hooks";

const Styled = {
  Container: styled.div({
    display: "flex",
    flexDirection: "column",
    gap: 20,
  }),
  TitleContainer: styled.div({
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 20,
    marginTop: 20,
  }),
  ProgressBar: styled.div({
    minWidth: 100,
  }),
};

export function ExistingQueries() {
  const navigate = useNavigate();
  const { width } = useViewportSize();

  const user = useUser();
  const { getToken } = useAuth();

  const { isLoading, isError, data } = useQuery({
    queryKey: ["jobs"],
    queryFn: async () => {
      const token = await getToken();
      return axios
        .get("https://node.kylelbrown.com/hotels/jobs", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data);
    },
  });

  if (isLoading || !user.isLoaded)
    return (
      <LoadingOverlay
        visible
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
    );

  if (!user.isSignedIn) {
    navigate("/sign-in");
  }

  if (isError) return <div>Error</div>;

  function getStatus(job: any) {
    const completionPercentage = !job?.calculatedStatus?.total_tasks
      ? 2
      : (job?.calculatedStatus?.completed_tasks /
          job?.calculatedStatus?.total_tasks) *
          100 ?? 0;

    return (
      <Styled.ProgressBar>
        <Progress
          value={completionPercentage}
          color={completionPercentage === 100 ? "green" : "blue"}
          animated={completionPercentage !== 100}
        />
      </Styled.ProgressBar>
    );
  }

  function handleQueryClick(jobId: string) {
    navigate(`/hotelQueries/${jobId}`);
  }

  const rows = data?.map((job: any) => (
    <Table.Tr onClick={() => handleQueryClick(job.id)} key={job.id}>
      <Table.Td>{job?.start_date}</Table.Td>
      <Table.Td>{job?.end_date}</Table.Td>
      <Table.Td>{getStatus(job)}</Table.Td>

      {width > 767 && (
        <>
          <Table.Td>{job?.number_of_days_in_resort}</Table.Td>
          <Table.Td>{job?.number_of_adults}</Table.Td>
          <Table.Td>{job?.number_of_children}</Table.Td>
        </>
      )}
    </Table.Tr>
  ));

  return (
    <AppShell>
      <Container size="sm">
        <Styled.TitleContainer>
          <Title order={2}>Existing Queries </Title>
        </Styled.TitleContainer>

        <Styled.Container>
          <Table highlightOnHover>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Start Date</Table.Th>
                <Table.Th>End Date</Table.Th>
                <Table.Th>Status</Table.Th>

                {width > 767 && (
                  <>
                    <Table.Th>Days in Resort</Table.Th>
                    <Table.Th>Adults</Table.Th>
                    <Table.Th>Children</Table.Th>
                  </>
                )}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </Styled.Container>
      </Container>
    </AppShell>
  );
}
