import { useNavigate, useParams } from "react-router-dom";
import {
  LoadingOverlay,
  Grid,
  Container,
  AppShell,
  Card,
  Image,
  MultiSelect,
  Button,
  Title,
  Space,
  Table,
  Text,
  Progress,
  Stepper,
  Badge,
  Blockquote,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import styled from "@emotion/styled";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useState } from "react";
import { useAuth, useUser } from "@clerk/clerk-react";
import { DatePickerInput } from "@mantine/dates";
import moment from "moment";
import { useViewportSize } from "@mantine/hooks";

const Styled = {
  Container: styled.div({
    display: "flex",
    flexDirection: "column",
    gap: 20,
  }),
  TitleContainer: styled.div({
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 20,
    marginTop: 20,
  }),
  ParamsContainer: styled.div({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 20,
    marginTop: 20,
  }),
  SmallTable: styled.div({
    maxHeight: 200,
    overflowY: "auto",
  }),
};

export function Result() {
  const [value, setValue] = useState<string[]>([]);

  const { width } = useViewportSize();

  const { jobId } = useParams();

  const navigate = useNavigate();

  const user = useUser();
  const { getToken } = useAuth();

  const { isLoading, isError, data } = useQuery({
    queryKey: [`jobs.${jobId}`],
    queryFn: async () => {
      const token = await getToken();
      return axios
        .get(`https://node.kylelbrown.com/hotels/jobs/${jobId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data);
    },
  });

  if (isLoading || !user.isLoaded)
    return (
      <LoadingOverlay
        visible
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
    );

  if (!user.isSignedIn) {
    navigate("/sign-in");
  }

  if (isError) return <div>Error</div>;

  if (
    data?.params?.start_date === null ||
    data?.params?.number_of_days_in_resort === null ||
    data?.params?.number_of_adults === null ||
    data?.params?.start_date === undefined ||
    data?.params?.number_of_days_in_resort === undefined ||
    data?.params?.number_of_adults === undefined
  ) {
    navigate("/hotelQueries");
  }

  const completionPercentage = !data?.calculatedStatus?.total_tasks
    ? 2
    : (data?.calculatedStatus?.completed_tasks /
        data?.calculatedStatus?.total_tasks) *
        100 ?? 0;

  function getUniqueHotelNames(jobResult: any): string[] {
    const hotels = jobResult.results;
    const hotelNames: Set<string> = new Set();

    for (const hotel of hotels) {
      hotelNames.add(hotel.hotel_name);
    }

    return Array.from(hotelNames);
  }

  const uniqueHotelNames: string[] = getUniqueHotelNames(data);

  const groupedByHotel = Object.values(
    data.results.reduce((acc: any, obj: any) => {
      acc[obj.hotel_id] = acc[obj.hotel_id] || [];
      acc[obj.hotel_id].push(obj);
      return acc;
    }, {})
  );

  const hotelsToShow = groupedByHotel.filter((hotelArray: any) => {
    if (hotelArray.length === 0 || !hotelArray[0].hotel_name) return false;

    return value.includes(hotelArray[0].hotel_name) || value.length === 0;
  });

  const sortedSubHotels = hotelsToShow.map((subArray: any) => {
    return subArray.sort((a: any, b: any) => {
      if (a.price.includes("Unavailable") && !b.price.includes("Unavailable"))
        return 1;
      if (b.price.includes("Unavailable") && !a.price.includes("Unavailable"))
        return -1;

      if (a.price.includes("Unavailable") && b.price.includes("Unavailable"))
        return 0;

      const priceA = parseFloat(a.price.replace(/[^0-9.]/g, ""));
      const priceB = parseFloat(b.price.replace(/[^0-9.]/g, ""));
      return priceA - priceB;
    });
  });

  const sortedHotels = sortedSubHotels.sort((subArray1, subArray2) => {
    const minPrice1 = subArray1.reduce((min: any, hotel: any) => {
      if (!hotel.price.includes("Unavailable")) {
        const price = parseFloat(hotel.price.replace(/[^0-9.]/g, ""));
        return min === null ? price : Math.min(min, price);
      }
      return min;
    }, null);

    const minPrice2 = subArray2.reduce((min: any, hotel: any) => {
      if (!hotel.price.includes("Unavailable")) {
        const price = parseFloat(hotel.price.replace(/[^0-9.]/g, ""));
        return min === null ? price : Math.min(min, price);
      }
      return min;
    }, null);

    // // Handle sorting when prices are unavailable
    if (minPrice1 === null && minPrice2 === null) {
      return 0;
    } else if (minPrice1 === null) {
      return 1;
    } else if (minPrice2 === null) {
      return -1;
    }

    return minPrice1 - minPrice2;
  });

  function renderCard(hotelResults: any) {
    if (!hotelResults[0].hotel_id) return null;
    const hotel = hotelResults[0];

    let image = "";
    if (hotel.hotel_name === "Disney's Yacht Club Resort") {
      image = "/images/yacht.jpg";
    } else if (hotel.hotel_name === "Disney's Coronado Springs Resort") {
      image = "/images/coronado.jpg";
    } else if (hotel.hotel_name === "Disney's Old Key West Resort") {
      image = "/images/oldkeywest.jpg";
    } else if (hotel.hotel_name === "Disney's All-Star Sports Resort") {
      image = "/images/sports.jpg";
    } else if (hotel.hotel_name === "Disney's All-Star Music Resort") {
      image = "/images/music.jpg";
    } else if (hotel.hotel_name === "Disney's All-Star Movies Resort") {
      image = "/images/movie.jpg";
    } else if (hotel.hotel_name === "Disney's Pop Century Resort") {
      image = "/images/pop.jpg";
    } else if (hotel.hotel_name === "Disney's Art of Animation Resort") {
      image = "/images/animation.jpg";
    } else if (hotel.hotel_name === "Disney's Animal Kingdom Lodge") {
      image = "/images/animalKingdomLodge.jpg";
    } else if (hotel.hotel_name === "Disney's Saratoga Springs Resort & Spa") {
      image = "/images/saratogaSprings.jpg";
    } else if (hotel.hotel_name === "Disney's Grand Floridian Resort & Spa") {
      image = "/images/grandFloridian.jpg";
    } else if (
      hotel.hotel_name === "Disney's Port Orleans Resort - Riverside"
    ) {
      image = "/images/portOrleansRiverside.jpg";
    } else if (hotel.hotel_name === "Disney's BoardWalk Villas") {
      image = "/images/boardwalkVillas.jpg";
    } else if (hotel.hotel_name === "Disney's Beach Club Villas") {
      image = "/images/beachClubVillas.jpg";
    } else if (
      hotel.hotel_name === "The Villas at Disney's Grand Floridian Resort & Spa"
    ) {
      image = "/images/grandFloridianVillas.jpg";
    } else if (hotel.hotel_name === "Disney's Polynesian Villas & Bungalows") {
      image = "/images/polyVillas.jpg";
    } else if (hotel.hotel_name === "Disney's Riviera Resort") {
      image = "/images/riviera.jpg";
    } else if (
      hotel.hotel_name === "Boulder Ridge Villas at Disney's Wilderness Lodge"
    ) {
      image = "/images/boulderRidgeVillas.jpg";
    } else if (
      hotel.hotel_name ===
      "Copper Creek Villas & Cabins at Disney's Wilderness Lodge"
    ) {
      image = "/images/cooper.jpg";
    } else if (
      hotel.hotel_name === "Disney's Animal Kingdom Villas - Jambo House"
    ) {
      image = "/images/jamboHouse.jpg";
    } else if (
      hotel.hotel_name === "Disney's Animal Kingdom Villas - Kidani Village"
    ) {
      image = "/images/kidaniVillage.jpg";
    } else if (
      hotel.hotel_name === "Bay Lake Tower at Disney's Contemporary Resort"
    ) {
      image = "/images/bayLakeTower.jpg";
    } else if (hotel.hotel_name === "Disney's Beach Club Resort") {
      image = "/images/beach.jpg";
    } else if (hotel.hotel_name === "Disney's Contemporary Resort") {
      image = "/images/contemporary.jpg";
    } else if (hotel.hotel_name === "Disney's Polynesian Village Resort") {
      image = "/images/poly.jpg";
    } else if (hotel.hotel_name === "Disney's Wilderness Lodge") {
      image = "/images/wildrenessLodge.jpg";
    } else if (
      hotel.hotel_name === "Disney's Fort Wilderness Resort & Campground"
    ) {
      image = "/images/fortWilderness.jpg";
    } else if (hotel.hotel_name === "Disney's Caribbean Beach Resort") {
      image = "/images/caribbean.jpg";
    } else if (
      hotel.hotel_name === "Disney's Port Orleans Resort - French Quarter"
    ) {
      image = "/images/portOrleansFrench.jpg";
    } else if (
      hotel.hotel_name ===
      "Disney's Boulder Ridge Villas at Disney's Wilderness Lodge"
    ) {
      image = "/images/boulderRidgeVillas.jpg";
    } else if (hotel.hotel_name === "Disney's BoardWalk Inn") {
      image = "/images/boardwalkIn.jpg";
    } else if (
      hotel.hotel_name === "The Campsites at Disney's Fort Wilderness Resort"
    ) {
      image = "/images/campsite.jpg";
    } else if (
      hotel.hotel_name === "Bay Lake Tower at Disney's Contemporary Resort"
    ) {
      image = "/images/bayLakeTower.jpg";
    } else if (
      hotel.hotel_name === "The Cabins at Disney's Fort Wilderness Resort"
    ) {
      image = "/images/cabinsAtFortWild.jpg";
    }

    return (
      <Card
        key={hotel.hotel_id}
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
      >
        <Card.Section>
          <Image src={image} height={160} alt={hotel.hotel_name} />
        </Card.Section>

        <Space h="md" />

        <Text size="lg" fw={700}>
          {hotel.hotel_name}
        </Text>

        <Space h="md" />

        <Styled.SmallTable>
          <Table highlightOnHover>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Check In</Table.Th>
                <Table.Th>Check Out</Table.Th>
                <Table.Th>Price / Night</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {hotelResults.map((result: any) => (
                <Table.Tr
                  key={`${result.check_in_date} + ${result.check_out_date}`}
                >
                  <Table.Td>
                    {moment(result.check_in_date).format("LL")}
                  </Table.Td>
                  <Table.Td>
                    {moment(result.check_out_date).format("LL")}
                  </Table.Td>
                  <Table.Td>{result.price}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Styled.SmallTable>

        {/* <Button color="blue" fullWidth mt="md" radius="md">
          Book Now
        </Button> */}
      </Card>
    );
  }

  return (
    <AppShell>
      <Container size="md">
        <Styled.TitleContainer>
          <Title order={2}>Results</Title>
        </Styled.TitleContainer>
        <Stepper active={4} onStepClick={() => {}}>
          <Stepper.Step
            label={() => (
              <DatePickerInput
                type="range"
                value={[
                  new Date(data.params?.start_date),
                  new Date(data.params?.end_date),
                ]}
                disabled
              />
            )}
          ></Stepper.Step>
          <Stepper.Step
            label={() => (
              <Badge variant="light" color="blue">
                {data.params?.number_of_days_in_resort} Days in Resort
              </Badge>
            )}
          ></Stepper.Step>
          <Stepper.Step
            label={() => (
              <Badge variant="light" color="blue">
                {parseInt(data.params?.number_of_adults) +
                  parseInt(data.params?.number_of_children)}{" "}
                People
              </Badge>
            )}
          ></Stepper.Step>
        </Stepper>

        <Space h="lg" />

        {completionPercentage !== 100 && (
          <Blockquote
            color="blue"
            icon={<IoIosInformationCircleOutline />}
            mt="sm"
          >
            Searching for all the prices for your stay can take some time!
            Please sit back and relax, and refresh the page in a few minutes
          </Blockquote>
        )}

        <Space h="lg" />

        <Progress
          size="20"
          value={completionPercentage}
          color={completionPercentage === 100 ? "green" : "blue"}
          animated={completionPercentage !== 100}
        />

        <Space h="lg" />
        <MultiSelect
          value={value}
          onChange={setValue}
          label="Filter Resorts"
          placeholder="Pick Resort"
          data={uniqueHotelNames}
        />
        <Space h="lg" />
        <Styled.Container>
          <Grid>
            {sortedHotels?.map((hotelResults: any) => {
              return (
                <Grid.Col
                  key={hotelResults?.[0].hotel_id}
                  span={width < 1000 ? 12 : 6}
                >
                  {renderCard(hotelResults)}
                </Grid.Col>
              );
            })}
          </Grid>
        </Styled.Container>
      </Container>
    </AppShell>
  );
}
