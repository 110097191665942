/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Image } from "@mantine/core";

// Keyframes for fading in the line at the start of the animation
const fadeIn = keyframes`
  0% {
    opacity: 0;  // Start invisible
  }
  100% {
    opacity: 1;  // Fade in to full opacity
  }
`;

// Keyframes for the "bullet" shooting effect with a trailing effect
const shootRight = keyframes`
  0% {
    transform: scaleX(0);  // Start with no width
    opacity: 1;  // Fully visible at start
  }
  50% {
    transform: scaleX(0.5); /* At midpoint, width is half */
    opacity: 1;  // Fully visible in the middle of animation
  }
  100% {
    transform: scaleX(1);  /* Fully scaled to the width of the road */
    opacity: 0;  /* Fade out the trail at the end */
  }
`;

// Keyframes for the "bullet" shooting effect with a trailing effect
const shootLeft = keyframes`
  0% {
    transform: scaleX(0);  // Start with no width
    opacity: 1;  // Fully visible at start
  }
  50% {
    transform: scaleX(-0.5); /* At midpoint, width is half */
    opacity: 1;  // Fully visible in the middle of animation
  }
  100% {
    transform: scaleX(-1);  /* Fully scaled to the width of the road */
    opacity: 0;  /* Fade out the trail at the end */
  }
`;

// Styled components
const Styled = {
  FeatureTitle: styled.div({
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "18px",
    fontStyle: "normal",
    letterSpacing: "-0.02em",
    lineHeight: "140%",
    paragraphSpacing: "40px",
    textAlign: "left",
    color: "#072130",
    textDecoration: "none",
    textTransform: "none",
  }),
  FeatureDescription: styled.div({
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0em",
    lineHeight: "140%",
    paragraphSpacing: "20px",
    textAlign: "start",
    color: "#50636d", // Using the color from the variable
    textDecoration: "none",
    textTransform: "none",
    marginBottom: 60,
  }),
  FeatureText: styled.div({
    display: "flex",
    flexDirection: "column",
    gap: 5,
    width: "100%",
    padding: 24,
  }),
  Title: styled.div({
    fontSize: 40,
    fontFamily: "Inter",
    fontWeight: 900,
    letterSpacing: "-.02em",
    color: "#072130",
    marginBottom: 30,
  }),

  DataGetting: styled.div({
    border: "1px solid #edeef2",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "#f8f9fd",
    borderRadius: "24px",
    display: "flex",
    flexDirection: "column",
    minHeight: "420px",
    overflow: "hidden",
    width: "100%",
  }),

  Section: styled.div({
    display: "flex",
    flexDirection: "column",
    gap: "64px",
    maxWidth: "1040px",
    justifyContent: "center",
    margin: "0 auto",
  }),

  BubbleRow: styled.div({
    display: "flex",
    alignItems: "center", // Horizontally align bubble and road
    gap: "16px", // Space between bubble and the road
    width: "100%", // Full width row
    position: "relative", // Position road behind bubble
    flex: "1 0 0px",
  }),

  DisneyBubble: styled.div({
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "50%", // Ensures the bubble is a perfect circle
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 3px 12px, rgba(0, 0, 0, 0.15) 0px 0px 0px 1px",
    width: "60px", // Set equal width and height for perfect circle
    height: "60px",
    zIndex: 1, // Ensure bubble appears above the road
  }),

  Image: styled.div({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),

  LineContainer: styled.div({
    height: "2px", // Height of the road
    width: "100%", // Full width of the container
    backgroundColor: "transparent", // Transparent so we only see road and animation
  }),

  Road: styled.div({
    position: "absolute",
    height: "2px", // Skinnier road
    width: "100%", // Full width of the container
    background:
      "linear-gradient(270deg, rgba(30, 144, 255, 0.2), rgba(106, 90, 205, 0.2))", // Subtle gradient for the road
    opacity: 0.4, // Slight transparency to make it subtle
    left: "0", // Start behind the bubble
  }),

  RoadLeft: styled.div({
    position: "absolute",
    height: "2px", // Skinnier road
    width: "100%", // Full width of the container
    background:
      "linear-gradient(270deg, rgba(30, 144, 255, 0.2), rgba(106, 90, 205, 0.2))", // Subtle gradient for the road
    opacity: 0.4, // Slight transparency to make it subtle
    right: "0", // Start behind the bubble
  }),

  AnimatedLine: styled.div({
    position: "absolute",
    height: "2px", // Match the height of the road
    width: "100%", // Full length of the road for the bullet effect
    background: "linear-gradient(270deg, #1e90ff, #6a5acd)", // Gradient color matching your home page
    transformOrigin: "left", // Originating the scale transformation from the left side
    animation: `${fadeIn} 0.5s ease-in, ${shootRight} 3s ease-out infinite`, // Slowed down animation and added infinite loop
    left: "0", // Start behind the bubble
  }),

  AnimatedLineLeft: styled.div({
    position: "absolute",
    height: "2px", // Match the height of the road
    width: "100%", // Full length of the road for the bullet effect
    background: "linear-gradient(270deg, #1e90ff, #6a5acd)", // Gradient color matching your home page
    transformOrigin: "left", // Originating the scale transformation from the left side
    animation: `${fadeIn} 0.5s ease-in, ${shootLeft} 3s ease-out infinite`, // Slowed down animation and added infinite loop
    left: "100%", // Start behind the bubble
  }),

  BubbleColumn: styled.div({
    display: "flex",
    flexDirection: "column",
  }),

  AnimationContainer: styled.div({
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    flex: "1 0 0px",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: "0px",
    justifyContent: "flex-start",
    padding: "0 64px",
    position: "relative",
    width: "100%",

    // Media query for small screens
    "@media (max-width: 768px)": {
      padding: "0 0px",
    },
    "@media (max-width: 480px)": {
      padding: "0 0px",
    },
  }),

  DataDestination: styled.div({
    alignContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 1px 4px #0000001a, 0 0 0 1px #0000001a",
    display: "flex",
    flex: "none",
    flexDirection: "column",
    flexWrap: "nowrap",
    gap: "0px",
    height: "250px",
    justifyContent: "flex-start",
    overflow: "hidden",
    padding: "0",
    position: "relative",
    width: "420px",
    willChange: "transform",
    zIndex: 2,

    // Media query for small screens
    "@media (max-width: 768px)": {
      width: "180px", // Adjust width to 100% on smaller screens
      height: "260px", // Adjust height for smaller screens if necessary
    },
    "@media (max-width: 480px)": {
      width: "180px", // Adjust width to 100% on smaller screens
      height: "260px", // Adjust height for smaller screens if necessary
    },
  }),

  FirstDataRow: styled.div({
    alignContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: "#ffffff80",
    display: "flex",
    flex: "none",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: "0px",
    height: "24px",
    justifyContent: "flex-start",
    overflow: "hidden",
    padding: "0",
    position: "relative",
    width: "420px",
  }),

  DataRow: styled.div({
    borderBottomWidth: "0px",
    borderColor: "#eaeaee",
    borderLeftWidth: "0px",
    borderRightWidth: "0px",
    borderStyle: "solid",
    borderTopWidth: "1px",
    alignContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: "#fff",
    display: "flex",
    flex: "none",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: "0px",
    height: "24px",
    justifyContent: "flex-start",
    overflow: "hidden",
    padding: "0",
    position: "relative",
    width: "420px",
  }),

  DataCellTop: styled.div({
    borderBottomWidth: "1px",
    borderColor: "#eaeaee",
    borderLeftWidth: "0px",
    borderRightWidth: "0px",
    borderStyle: "solid",
    borderTopWidth: "0px",
    backgroundColor: "#f8f9fd80",
    flex: "1 0 0px",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    width: "1px",
  }),

  DataCell: styled.div({
    borderBottomWidth: "0px",
    borderColor: "#eaeaee",
    borderLeftWidth: "0px",
    borderRightWidth: "1px",
    borderStyle: "solid",
    borderTopWidth: "0px",
    backgroundColor: "#fff",
    flex: "1 0 0px",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    width: "1px",
  }),

  DataBlueCell: styled.div({
    borderBottomWidth: "0px",
    borderColor: "#eaeaee",
    borderLeftWidth: "0px",
    borderRightWidth: "1px",
    borderStyle: "solid",
    borderTopWidth: "0px",
    backgroundColor: "rgba(30, 144, 255, 0.1)", // Subtle gradient for the road,
    flex: "1 0 0px",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    width: "1px",
  }),

  LineStackContainer: styled.div({
    position: "relative",
  }),

  DataDestinationContainer: styled.div({
    // position: "absolute",
  }),

  MDFLogoDestination: styled.div({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,

    width: 200,
    height: 200,
  }),

  DisneySourceColumn: styled.div({
    display: "flex",
    flex: "1 0 1px",
    alignContent: "center",
    alignItems: "center",
  }),

  DisneySourceContainer: styled.div({
    display: "flex",
    flex: "1 0 1px",
    flexDirection: "column",
    gap: 32,
  }),
};

export const HowItWorks = () => {
  return (
    <>
      <Styled.Title>How Does this Work?</Styled.Title>

      <Styled.Section>
        <Styled.DataGetting>
          <Styled.FeatureText>
            <Styled.FeatureTitle>
              Pulls In Live Disney Pricing Data for Your Available Travel Window
            </Styled.FeatureTitle>
            <Styled.FeatureDescription>
              No need to do 100 searches your self, we can look over a span on
              months what the cheapest travel days are for all Disney World
              Hotels.
            </Styled.FeatureDescription>
            <Styled.AnimationContainer>
              <Styled.DisneySourceContainer>
                <Styled.DisneySourceColumn>
                  <Styled.BubbleColumn>
                    <Styled.BubbleRow>
                      <Styled.DisneyBubble>
                        <Styled.Image>
                          <Image src={"/images/disneyLogo.png"} />
                        </Styled.Image>
                      </Styled.DisneyBubble>
                    </Styled.BubbleRow>
                  </Styled.BubbleColumn>

                  <Styled.LineContainer>
                    <Styled.LineStackContainer>
                      <Styled.Road />
                      <Styled.AnimatedLine />
                    </Styled.LineStackContainer>
                  </Styled.LineContainer>
                </Styled.DisneySourceColumn>
                <Styled.DisneySourceColumn>
                  <Styled.BubbleColumn>
                    <Styled.BubbleRow>
                      <Styled.DisneyBubble>
                        <Styled.Image>
                          <Image src={"/images/disneyLogo.png"} />
                        </Styled.Image>
                      </Styled.DisneyBubble>
                    </Styled.BubbleRow>
                  </Styled.BubbleColumn>

                  <Styled.LineContainer>
                    <Styled.LineStackContainer>
                      <Styled.Road />
                      <Styled.AnimatedLine />
                    </Styled.LineStackContainer>
                  </Styled.LineContainer>
                </Styled.DisneySourceColumn>
                <Styled.DisneySourceColumn>
                  <Styled.BubbleColumn>
                    <Styled.BubbleRow>
                      <Styled.DisneyBubble>
                        <Styled.Image>
                          <Image src={"/images/disneyLogo.png"} />
                        </Styled.Image>
                      </Styled.DisneyBubble>
                    </Styled.BubbleRow>
                  </Styled.BubbleColumn>

                  <Styled.LineContainer>
                    <Styled.LineStackContainer>
                      <Styled.Road />
                      <Styled.AnimatedLine />
                    </Styled.LineStackContainer>
                  </Styled.LineContainer>
                </Styled.DisneySourceColumn>
              </Styled.DisneySourceContainer>

              <Styled.DataDestinationContainer>
                <Styled.DataDestination>
                  <Styled.MDFLogoDestination>
                    <Image src={"/images/mdfLogo.png"} />
                  </Styled.MDFLogoDestination>
                  <Styled.DataRow>
                    <Styled.DataCellTop />
                    <Styled.DataCellTop />
                    <Styled.DataCellTop />
                    <Styled.DataCellTop />
                    <Styled.DataCellTop />
                    <Styled.DataCellTop />
                    <Styled.DataCellTop />
                    <Styled.DataCellTop />
                  </Styled.DataRow>
                  <Styled.DataRow>
                    <Styled.DataBlueCell />
                    <Styled.DataCell />
                    <Styled.DataBlueCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                  </Styled.DataRow>
                  <Styled.DataRow>
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataBlueCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                  </Styled.DataRow>
                  <Styled.DataRow>
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataBlueCell />
                    <Styled.DataCell />
                  </Styled.DataRow>
                  <Styled.DataRow>
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataBlueCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                  </Styled.DataRow>
                  <Styled.DataRow>
                    <Styled.DataBlueCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                  </Styled.DataRow>
                  <Styled.DataRow>
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataBlueCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                  </Styled.DataRow>
                  <Styled.DataRow>
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataBlueCell />
                    <Styled.DataCell />
                  </Styled.DataRow>
                  <Styled.DataRow>
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                  </Styled.DataRow>
                  <Styled.DataRow>
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataBlueCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                  </Styled.DataRow>
                  <Styled.DataRow>
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                    <Styled.DataBlueCell />
                    <Styled.DataCell />
                    <Styled.DataCell />
                  </Styled.DataRow>
                </Styled.DataDestination>
              </Styled.DataDestinationContainer>

              <Styled.DisneySourceContainer>
                <Styled.DisneySourceColumn>
                  <Styled.LineContainer>
                    <Styled.LineStackContainer>
                      <Styled.RoadLeft />
                      <Styled.AnimatedLineLeft />
                    </Styled.LineStackContainer>
                  </Styled.LineContainer>

                  <Styled.BubbleColumn>
                    <Styled.BubbleRow>
                      <Styled.DisneyBubble>
                        <Styled.Image>
                          <Image src={"/images/disneyLogo.png"} />
                        </Styled.Image>
                      </Styled.DisneyBubble>
                    </Styled.BubbleRow>
                  </Styled.BubbleColumn>
                </Styled.DisneySourceColumn>
                <Styled.DisneySourceColumn>
                  <Styled.LineContainer>
                    <Styled.LineStackContainer>
                      <Styled.RoadLeft />
                      <Styled.AnimatedLineLeft />
                    </Styled.LineStackContainer>
                  </Styled.LineContainer>

                  <Styled.BubbleColumn>
                    <Styled.BubbleRow>
                      <Styled.DisneyBubble>
                        <Styled.Image>
                          <Image src={"/images/disneyLogo.png"} />
                        </Styled.Image>
                      </Styled.DisneyBubble>
                    </Styled.BubbleRow>
                  </Styled.BubbleColumn>
                </Styled.DisneySourceColumn>
                <Styled.DisneySourceColumn>
                  <Styled.LineContainer>
                    <Styled.LineStackContainer>
                      <Styled.RoadLeft />
                      <Styled.AnimatedLineLeft />
                    </Styled.LineStackContainer>
                  </Styled.LineContainer>

                  <Styled.BubbleColumn>
                    <Styled.BubbleRow>
                      <Styled.DisneyBubble>
                        <Styled.Image>
                          <Image src={"/images/disneyLogo.png"} />
                        </Styled.Image>
                      </Styled.DisneyBubble>
                    </Styled.BubbleRow>
                  </Styled.BubbleColumn>
                </Styled.DisneySourceColumn>
              </Styled.DisneySourceContainer>
            </Styled.AnimationContainer>
          </Styled.FeatureText>
        </Styled.DataGetting>
      </Styled.Section>
    </>
  );
};
