import { Tabs } from "@mantine/core";
import { TbRollercoaster } from "react-icons/tb";
import styled from "@emotion/styled";
import { ParkAttractionTable } from "./park-attraction-table";

const Styled = {
  Container: styled.div({
    display: "flex",
  }),
  CardContainer: styled.div({
    width: "100vw",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: "10px",
  }),
};

export function AttractionSelection() {
  return (
    <Styled.Container>
      <Tabs defaultValue="magicKingdom">
        <Tabs.List>
          <Tabs.Tab value="magicKingdom" leftSection={<TbRollercoaster />}>
            Magic Kingdom
          </Tabs.Tab>
          <Tabs.Tab value="epcot" leftSection={<TbRollercoaster />}>
            Epcot
          </Tabs.Tab>
          <Tabs.Tab value="hollywoodStudios" leftSection={<TbRollercoaster />}>
            Hollywood Studios
          </Tabs.Tab>
          <Tabs.Tab value="animalKingdom" leftSection={<TbRollercoaster />}>
            Animal Kingdom
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="magicKingdom">
          <Styled.CardContainer>
            <ParkAttractionTable park="magicKingdom" />
          </Styled.CardContainer>
        </Tabs.Panel>

        <Tabs.Panel value="epcot">
          <Styled.CardContainer>
            <ParkAttractionTable park="epcot" />
          </Styled.CardContainer>
        </Tabs.Panel>

        <Tabs.Panel value="hollywoodStudios">
          <Styled.CardContainer>
            <ParkAttractionTable park="hollywoodStudios" />
          </Styled.CardContainer>
        </Tabs.Panel>
        <Tabs.Panel value="animalKingdom">
          <Styled.CardContainer>
            <ParkAttractionTable park="animalKingdom" />
          </Styled.CardContainer>
        </Tabs.Panel>
      </Tabs>
    </Styled.Container>
  );
}
