import { Route, Routes } from "react-router-dom";
import { NewHotelQuery } from "../pages/hotels/new-query/new-query";
import { QueriesBase } from "../pages/hotels/queries-base/queries-base";
import { ExistingQueries } from "../pages/hotels/existing-queries/existing-queries";
import { Result } from "../pages/hotels/result/result";
import { SignIn, SignUp } from "@clerk/clerk-react";
import { Provision } from "../components/Provision";
import { Hotels } from "../pages/hotels/hotels";
import { Home } from "../pages/home/home";
import { PaymentSuccess } from "../pages/hotels/payment-success/payment-success";
import { AppShell } from "@mantine/core";
import styled from "@emotion/styled";
import { Help } from "../pages/help/help";
import { Terms } from "../pages/home/Terms";
import { Privacy } from "../pages/home/Privacy";
import { AttractionSelection } from "../pages/attraction-selection/attraction-selection";
import { AccessToken } from "../pages/access-token/access-token";

const Styled = {
  Centered: styled.div({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  }),
};

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/packages" element={<Hotels />} />
      <Route path="/provision" element={<Provision />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route
        path="/sign-in"
        element={
          <Styled.Centered>
            <SignIn redirectUrl="/provision" signUpUrl="/sign-up" />
          </Styled.Centered>
        }
      />
      <Route
        path="/sign-up"
        element={
          <Styled.Centered>
            <SignUp redirectUrl="/provision" signInUrl="/sign-in" />
          </Styled.Centered>
        }
      />
      <Route
        path="/newHotelQuery"
        element={
          <AppShell.Main>
            <NewHotelQuery />
          </AppShell.Main>
        }
      />
      <Route
        path="/hotelQueries"
        element={
          <AppShell.Main>
            <QueriesBase />
          </AppShell.Main>
        }
      >
        <Route index element={<ExistingQueries />} />
        <Route path=":jobId" element={<Result />} />
      </Route>
      <Route
        path="/help"
        element={
          <AppShell.Main>
            <Help />
          </AppShell.Main>
        }
      />
      <Route
        path="/attractions"
        element={
          <AppShell.Main>
            <AttractionSelection />
          </AppShell.Main>
        }
      />
      <Route
        path="/accessToken"
        element={
          <AppShell.Main>
            <AccessToken />
          </AppShell.Main>
        }
      />
      {/*<Route path="/dining" element={<Dining />} /> */}
      <Route
        path="*"
        element={
          <div style={{ padding: "1rem" }}>
            <p>Page not found.</p>
          </div>
        }
      />
    </Routes>
  );
}
