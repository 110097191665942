import styled from "@emotion/styled";
import { Text, Divider, Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { FaRegPlusSquare } from "react-icons/fa";
import { FaListCheck } from "react-icons/fa6";
import { MdAttachMoney } from "react-icons/md";
import { IoIosHelpCircleOutline, IoMdKey } from "react-icons/io";
import { TbRollercoaster } from "react-icons/tb";
import { useUser } from "@clerk/clerk-react";

const Styled = {
  NavBarHeaderText: styled.div({
    display: "flex",
  }),
  IconButton: styled.div({
    display: "flex",
  }),
  IconText: styled.div({
    display: "flex",
    gap: 4,
    alignItems: "center",
  }),
};

export function Navbar({ toggle }: { toggle: () => void }) {
  const navigate = useNavigate();
  const user = useUser();

  return (
    <nav>
      <Styled.NavBarHeaderText>
        <Text size="xs" fw={500}>
          Disney World Resorts
        </Text>
      </Styled.NavBarHeaderText>

      <Styled.IconButton>
        <Button
          onClick={() => {
            navigate("/newHotelQuery");
            toggle();
          }}
          variant="subtle"
          size="sm"
          fullWidth
          justify="space-between"
          color="rgba(0, 0, 0, 1)"
          radius="xs"
          leftSection={
            <Styled.IconText>
              <FaRegPlusSquare style={{ color: "#686868" }} />
              <Text size="sm" fw={500}>
                New Query
              </Text>
            </Styled.IconText>
          }
        ></Button>
      </Styled.IconButton>

      <Styled.IconButton>
        <Button
          onClick={() => {
            navigate("/hotelQueries");
            toggle();
          }}
          variant="subtle"
          size="sm"
          fullWidth
          justify="space-between"
          color="rgba(0, 0, 0, 1)"
          radius="xs"
          leftSection={
            <Styled.IconText>
              <FaListCheck style={{ color: "#686868" }} />
              <Text size="sm" fw={500}>
                Existing Queries
              </Text>
            </Styled.IconText>
          }
        ></Button>
      </Styled.IconButton>

      <Styled.IconButton>
        <Button
          onClick={() => {
            navigate("/packages");
            toggle();
          }}
          variant="subtle"
          size="sm"
          fullWidth
          justify="space-between"
          color="rgba(0, 0, 0, 1)"
          radius="xs"
          leftSection={
            <Styled.IconText>
              <MdAttachMoney style={{ color: "#686868" }} />
              <Text size="sm" fw={500}>
                Buy More!
              </Text>
            </Styled.IconText>
          }
        ></Button>
      </Styled.IconButton>

      <Divider my="lg" />

      <Styled.IconButton>
        <Button
          onClick={() => {
            navigate("/help");
            toggle();
          }}
          variant="subtle"
          size="sm"
          fullWidth
          justify="space-between"
          color="rgba(0, 0, 0, 1)"
          radius="xs"
          leftSection={
            <Styled.IconText>
              <IoIosHelpCircleOutline style={{ color: "#686868" }} />
              <Text size="sm" fw={500}>
                Need Help?
              </Text>
            </Styled.IconText>
          }
        ></Button>
      </Styled.IconButton>

      {user?.user?.primaryEmailAddress?.emailAddress ===
        "kb16brown@gmail.com" && (
        <>
          <Styled.NavBarHeaderText>
            <Text size="xs" fw={500}>
              Genie Plus
            </Text>
          </Styled.NavBarHeaderText>

          <Styled.IconButton>
            <Button
              onClick={() => navigate("/attractions")}
              variant="subtle"
              size="sm"
              justify="space-between"
              fullWidth
              color="rgba(0, 0, 0, 1)"
              radius="xs"
              leftSection={
                <Styled.IconText>
                  <TbRollercoaster style={{ color: "#686868" }} />
                  <Text size="sm" fw={500}>
                    Attractions
                  </Text>
                </Styled.IconText>
              }
            ></Button>
          </Styled.IconButton>

          <Divider my="lg" />

          {/* <Styled.NavBarHeaderText>
        <Text size="xs" fw={500}>
          Dining Reservations
        </Text>
      </Styled.NavBarHeaderText>

      <Styled.IconButton>
        <Button
          onClick={() => navigate("/dining")}
          variant="subtle"
          size="sm"
          fullWidth
          justify="space-between"
          color="rgba(0, 0, 0, 1)"
          radius="xs"
          leftSection={
            <Styled.IconText>
              <MdOutlineLocalDining style={{ color: "#686868" }} />
              <Text size="sm" fw={500}>
                Dining
              </Text>
            </Styled.IconText>
          }
        ></Button>
      </Styled.IconButton>

      <Divider my="lg" /> */}

          <Styled.NavBarHeaderText>
            <Text size="xs" fw={500}>
              Authorization
            </Text>
          </Styled.NavBarHeaderText>

          <Styled.IconButton>
            <Button
              onClick={() => navigate("/accessToken")}
              variant="subtle"
              size="sm"
              color="rgba(0, 0, 0, 1)"
              radius="xs"
              fullWidth
              justify="space-between"
              leftSection={
                <Styled.IconText>
                  <IoMdKey style={{ color: "#686868" }} />
                  <Text size="sm" fw={500}>
                    Access Token
                  </Text>
                </Styled.IconText>
              }
            ></Button>
          </Styled.IconButton>
        </>
      )}
    </nav>
  );
}
