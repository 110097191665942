import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Checkbox, Table } from "@mantine/core";
import { LoadingOverlay } from "@mantine/core";
import axios from "axios";
import styled from "@emotion/styled";

const Styled = {
  TableContainer: styled.div({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    margin: "20px",
  }),
  RowItem: styled.div({
    display: "flex",
    alignItems: "center",
  }),
  Card: styled.div({
    flex: "0 0 2",
  }),
  FullWidth: styled.div({
    width: "100vw",
  }),
};

export function ParkAttractionTable({ park }: { park: string }) {
  const queryClient = useQueryClient();

  const { isLoading, isError, data } = useQuery({
    queryKey: [`attractions.${park}`],
    queryFn: () =>
      axios
        .get(`https://node.kylelbrown.com/disney/${park}/attractions`)
        .then((res) => res.data),
  });

  const updateAttraction = async (attraction: any) => {
    await axios.post(
      `https://node.kylelbrown.com/disney/attractions/${attraction.id}`,
      {
        shouldNotify: attraction.shouldNotify,
      }
    );
  };

  const mutation = useMutation({
    mutationFn: updateAttraction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`attractions.${park}`] });
    },
  });

  if (isLoading)
    return (
      <LoadingOverlay
        visible
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
    );

  if (isError) return <div>Error</div>;

  return (
    <Styled.TableContainer>
      <Table striped withColumnBorders>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Ride Name</Table.Th>
            <Table.Th>Should Notify?</Table.Th>
            <Table.Th>Wait Time</Table.Th>
            <Table.Th>Genie +</Table.Th>
            <Table.Th>LL</Table.Th>
            <Table.Th>LL Price</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data?.map((attraction: any) => (
            <Table.Tr key={attraction.id}>
              <Table.Td>
                <Styled.RowItem>{attraction.name}</Styled.RowItem>
              </Table.Td>
              <Table.Td>
                <Checkbox
                  checked={attraction.shouldNotify}
                  onChange={() =>
                    mutation.mutate({
                      id: attraction.id,
                      shouldNotify: !attraction.shouldNotify,
                    })
                  }
                />
              </Table.Td>
              <Table.Td>
                <Styled.RowItem>{attraction.waitTime}</Styled.RowItem>
              </Table.Td>
              <Table.Td>
                <Styled.RowItem>{attraction.geniePlus}</Styled.RowItem>
              </Table.Td>
              <Table.Td>
                <Styled.RowItem>{attraction.individualLL}</Styled.RowItem>
              </Table.Td>
              <Table.Td>
                <Styled.RowItem>{attraction.individualLLPrice}</Styled.RowItem>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Styled.TableContainer>
  );
}
