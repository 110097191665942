/** @jsxImportSource @emotion/react */
import { useUser } from "@clerk/clerk-react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Image } from "@mantine/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Styled = {
  Section: styled.div({
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "stretch",
    padding: "50px",
  }),
  Logo: styled.div({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  }),
  Image: styled.div({
    width: 200,
    height: 200,
  }),
  LogoText: styled.div({
    fontFamily: '"Inter"',
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "-.02em",
    color: "rgba(0, 0, 0, 0.9)",
    textShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    lineHeight: 1,

    "@media (max-width: 768px)": {
      display: "none",
    },
    "@media (max-width: 480px)": {
      display: "none",
    },
  }),
  LegalColumn: styled.div({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 50,
  }),
  LegalHeader: styled.div({
    fontFamily: '"Inter"',
    fontSize: "15px",
    fontWeight: "600",
    letterSpacing: "-0.02em",
    lineHeight: "140%",
    textColor: "var(--extracted-r6o4lv, rgb(52, 52, 52))",
  }),
  PrivacyPolicy: styled.div({
    fontFamily: '"Inter"',
    fontSize: "15px",
    fontWeight: "400",
    letterSpacing: "-.02em",
    color: "rgb(107, 107, 107)",
    textShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    lineHeight: 1,
    cursor: "pointer",

    textColor: "#606E76",
    textDecoration: "none",
  }),
  Terms: styled.div({
    fontFamily: '"Inter"',
    fontSize: "15px",
    fontWeight: "400",
    letterSpacing: "-.02em",
    color: "rgb(107, 107, 107)",
    textShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    lineHeight: 1,
    cursor: "pointer",

    textColor: "#606E76",
    textDecoration: "none",
  }),
};

const gradientBackground = css`
  width: 100vw;
  height: 10vh;
`;

export const FancyFooter = () => {
  const navigate = useNavigate();

  return (
    <div css={gradientBackground}>
      <Styled.Section>
        <Styled.Logo>
          <Styled.Image>
            <Image src={"/images/mdfLogo.png"} />
          </Styled.Image>
          <Styled.LogoText>Magical Date Finder</Styled.LogoText>
        </Styled.Logo>
        <Styled.LegalColumn>
          <Styled.LegalHeader>Legal</Styled.LegalHeader>
          <Styled.PrivacyPolicy onClick={() => navigate("/privacy")}>
            Privacy Policy
          </Styled.PrivacyPolicy>
          <Styled.Terms onClick={() => navigate("/terms")}>
            Terms of Service
          </Styled.Terms>
        </Styled.LegalColumn>
      </Styled.Section>
    </div>
  );
};
