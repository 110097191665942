import { useAuth, useUser } from "@clerk/clerk-react";
import { LoadingOverlay } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export function Provision() {
  const navigate = useNavigate();
  const { getToken } = useAuth();

  const { isLoading } = useQuery({
    queryKey: ["provision"],
    queryFn: async () => {
      const token = await getToken();
      axios
        .get(`https://node.kylelbrown.com/hotels/provision`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          navigate("/newHotelQuery");
          return res.data;
        });
    },
  });

  return (
    <LoadingOverlay
      visible
      zIndex={1000}
      overlayProps={{ radius: "sm", blur: 2 }}
    />
  );
}
