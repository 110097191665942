import styled from "@emotion/styled";
import { Grid } from "@mantine/core";

const Styled = {
  Terms: styled.p({}),
};

export function Terms() {
  return (
    <Grid
      align="center"
      style={{
        paddingTop: 50,
        paddingBottom: 20,
        backgroundColor: "white",
      }}
    >
      <Grid.Col style={{}} span={{ base: 12, md: 4 }}></Grid.Col>
      <Grid.Col style={{}} span={{ base: 12, md: 4 }}>
        <Styled.Terms>
          <h1>Terms of Service</h1>
          <p>
            <strong>Last Updated: 05/19/2024</strong>
          </p>

          <p>
            Welcome to Magical Date Finder! By accessing or using our service,
            you agree to comply with and be bound by the following terms and
            conditions.
          </p>

          <h2>1. Acceptance of Terms</h2>
          <p>
            By using Magical Date Finder, you agree to be bound by these Terms
            of Service. If you do not agree, do not use the service.
          </p>

          <h2>2. Description of Service</h2>
          <p>
            Magical Date Finder provides a platform to search Disney World Hotel
            Resort data to find the cheapest set of days to travel. Users
            receive one limited free trial, with options for payment plans for
            additional queries.
          </p>

          <h2>3. User Accounts</h2>
          <p>
            Users must register for an account to access the service. Users are
            responsible for maintaining the confidentiality of their account
            information. We reserve the right to terminate accounts at our
            discretion.
          </p>

          <h2>4. Free Trial</h2>
          <p>
            Each user is entitled to one limited free trial. The specifics of
            the free trial may change at our discretion.
          </p>

          <h2>5. Payment Plans</h2>
          <p>
            Payment plans are available for users who need additional queries.
            Payments are non-refundable. We reserve the right to modify our
            pricing and payment plans at any time.
          </p>

          <h2>6. Use of the Service</h2>
          <p>
            Users agree to use the service only for lawful purposes. Any
            unauthorized use of the service is prohibited. We reserve the right
            to terminate access to the service for any user who violates these
            terms.
          </p>

          <h2>7. Intellectual Property</h2>
          <p>
            All content, trademarks, and data provided by Magical Date Finder
            are the property of [Your Company Name] and are protected by
            applicable laws.
          </p>

          <h2>8. Limitation of Liability</h2>
          <p>
            Magical Date Finder is provided "as is" and "as available" without
            any warranties. We are not liable for any damages arising from the
            use of our service.
          </p>

          <h2>9. Changes to Terms</h2>
          <p>
            We may update these Terms of Service at any time. Users are
            encouraged to review these terms periodically.
          </p>

          <h2>10. Contact Information</h2>
          <p>
            For any questions regarding these Terms of Service, please contact
            us at kyle@brownsmediagroup.com
          </p>
        </Styled.Terms>
      </Grid.Col>
      <Grid.Col style={{}} span={{ base: 12, md: 4 }}></Grid.Col>
    </Grid>
  );
}
