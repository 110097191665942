import { useAuth, useUser } from "@clerk/clerk-react";
import { LoadingOverlay } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export function PaymentSuccess() {
  const { getToken } = useAuth();
  const user = useUser();

  const { isLoading, isError } = useQuery({
    queryKey: ["hotelList"],
    queryFn: async () => {
      const token = await getToken();
      return axios
        .get("https://node.kylelbrown.com/hotels/list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data);
    },
  });

  if (isLoading || !user.isLoaded)
    return (
      <LoadingOverlay
        visible
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
    );

  if (isError) return <div>Error</div>;

  return <div>Thanks for subscribing!</div>;
}
