import { Card, Image, Group, Text, Badge } from "@mantine/core";

export function Help() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Image
          src="/images/mdfLogo.png"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 120,
          }}
        />

        <Group justify="space-between" mt="md" mb="xs">
          <Text fw={500}>Contact Us</Text>
        </Group>

        <Group justify="space-between" mt="md" mb="xs">
          <Text size="sm" c="dimmed">
            With any suggestions, concerns or issues, please contact us at:
          </Text>
        </Group>

        <Group justify="space-between" mt="md" mb="xs">
          <Badge color="blue">kyle@brownsmediagroup.com</Badge>
        </Group>
      </Card>
    </div>
  );
}
