import { AppRoutes } from "./Routes/routes";
import "./App.css";
import { AppShell, Burger, Button, Group, Image, Grid } from "@mantine/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { Navbar } from "./components/Navbar/Navbar";
import { IoMdLock } from "react-icons/io";
import { FaArrowRightLong } from "react-icons/fa6";
import { SignedIn, SignedOut, UserButton } from "@clerk/clerk-react";
import { useViewportSize } from "@mantine/hooks";
import styled from "@emotion/styled";

function App() {
  const [opened, { toggle }] = useDisclosure();
  const navigate = useNavigate();
  const { width } = useViewportSize();
  const location = useLocation();

  const Styled = {
    Mdf: styled.div({
      color: "black",
    }),
    CompanyName: styled.div({
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
    }),
    Burger: styled.div({
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: 10,
    }),
    User: styled.div({
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: 10,
    }),
  };

  return (
    <div className="App">
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: 200,
          breakpoint: "sm",
          collapsed: { mobile: !opened && location.pathname !== "/" },
        }}
        padding="md"
      >
        <AppShell.Header
          styles={{
            header: {
              backgroundColor: "white",
            },
          }}
        >
          <Grid grow justify="space-between" align="center">
            <SignedIn>
              <Grid.Col span="content" order={{ base: 1, sm: 2 }}>
                <Styled.Burger>
                  <Burger
                    opened={opened}
                    onClick={toggle}
                    hiddenFrom="sm"
                    size="sm"
                  />
                </Styled.Burger>
              </Grid.Col>
            </SignedIn>
            <Grid.Col span="auto" order={{ base: 2, sm: 1 }}>
              <Styled.CompanyName onClick={() => navigate("/")}>
                <Image src={"/images/mdfLogo.png"} height={60} />

                {width > 767 && <Styled.Mdf>Magical Date Finder</Styled.Mdf>}
              </Styled.CompanyName>
            </Grid.Col>
            <Grid.Col span="auto" order={{ base: 3, sm: 3 }}>
              <Styled.User>
                <>
                  <SignedIn>
                    <UserButton afterSignOutUrl="/sign-in" />
                  </SignedIn>
                  <SignedOut>
                    <Link to="/sign-in">
                      <Button
                        leftSection={<IoMdLock size={14} />}
                        rightSection={<FaArrowRightLong size={14} />}
                      >
                        Sign In
                      </Button>
                    </Link>
                  </SignedOut>
                </>
              </Styled.User>
            </Grid.Col>
          </Grid>
        </AppShell.Header>

        <SignedIn>
          {location.pathname !== "/" && (
            <AppShell.Navbar p="md">
              <Navbar toggle={toggle} />
            </AppShell.Navbar>
          )}
        </SignedIn>
        <AppRoutes />
      </AppShell>
    </div>
  );
}

export default App;
