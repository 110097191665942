import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export function AccessToken() {
  const { isLoading, isError, data } = useQuery({
    queryKey: ["accessToken"],
    queryFn: () =>
      axios
        .get("https://node.kylelbrown.com/disney/accessToken")
        .then((res) => res.data),
  });

  if (isLoading) return <div>Loading...</div>;

  if (isError) return <div>Error</div>;

  return <div>{data}</div>;
}
