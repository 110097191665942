import {
  Alert,
  AppShell,
  Button,
  Container,
  Flex,
  Input,
  NumberInput,
  Space,
} from "@mantine/core";
import { LoadingOverlay, Title, MultiSelect } from "@mantine/core";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import axios from "axios";
import { DatePickerInput } from "@mantine/dates";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useAuth, useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { CiCircleInfo } from "react-icons/ci";
import { MdError } from "react-icons/md";
import { getPlanDetailsFromString } from "../../../utils/plan-details";

const Styled = {
  ResortInput: styled.div({
    minWidth: 350,
    display: "flex",
    alignContent: "center",
    gap: 20,
  }),
  ResortInputPicker: styled.div({
    minWidth: 350,
  }),
  AllResortsButton: styled.div({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  }),
  TitleContainer: styled.div({
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 20,
    marginTop: 20,
  }),
  Container: styled.div({
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingBottom: 400,
  }),
  Alert: styled.div({
    width: 400,
  }),
  ErrorAlert: styled.div({
    width: 400,
  }),
};

export function NewHotelQuery() {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [selectedResorts, setSelectedResorts] = useState<string[]>([]);
  const [numberOfDays, setNumberOfDays] = useState<string | number>(2);
  const [numberOfAdults, setNumberOfAdults] = useState<string | number>(2);
  const [numberOfChildren, setNumberOfChildren] = useState<string | number>(0);
  const [numberOfRooms, setNumberOfRooms] = useState<string | number>(1);
  const [kidAges, setKidAges] = useState<string[] | number[]>([]);

  const [dateErrorMessage, setDateErrorMessage] = useState<string>("");
  const [hotelErrorMessage, setHotelErrorMessage] = useState<string>("");

  const navigate = useNavigate();
  const user = useUser();
  const { getToken } = useAuth();

  const userInfo = useQuery({
    queryKey: ["userInfo"],
    queryFn: async () => {
      const token = await getToken();
      return axios
        .get("https://node.kylelbrown.com/hotels/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data);
    },
  });

  const { isLoading, isError, data } = useQuery({
    queryKey: ["hotelList"],
    queryFn: async () => {
      const token = await getToken();
      return axios
        .get("https://node.kylelbrown.com/hotels/list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data);
    },
  });

  function convertNamesToIds(namesArray: string[], hotelsList: any) {
    const ids = namesArray.map((name) => {
      const hotel = hotelsList.find((hotel: any) => hotel.hotel_name === name);
      return hotel ? hotel.id : null;
    });
    return ids.filter((id) => id !== null);
  }

  const sendResortQuery = async () => {
    const startDate = moment(dateRange[0]).format("YYYY-MM-DD");
    const endDate = moment(dateRange[1]).format("YYYY-MM-DD");
    const resortIds = convertNamesToIds(selectedResorts, data);

    let queryString = `https://node.kylelbrown.com/hotels?startDate=${startDate}&endDate=${endDate}&numberOfAdults=${numberOfAdults}&numberOfChildren=${numberOfChildren}&numberOfRooms=${numberOfRooms}&numberOfDaysInResort=${numberOfDays}&kidsAges=${JSON.stringify(
      kidAges
    )}`;
    queryString = queryString.concat(
      resortIds.map((id) => `&hotels=${id}`).join("")
    );

    const token = await getToken();
    return await axios.get(queryString, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const mutation = useMutation({
    mutationFn: sendResortQuery,
    onSuccess: (data: any) => {
      const jobId = data?.data?.jobId;
      navigate(`/hotelQueries/${jobId}`);
    },
  });

  useEffect(() => {
    const startDate = moment(dateRange[0]).format("YYYY-MM-DD");
    const endDate = moment(dateRange[1]).format("YYYY-MM-DD");

    if (startDate === "Invalid date" || endDate === "Invalid date") {
      setDateErrorMessage("");
      return;
    }
    if (!userInfo?.data?.plantype) {
      setDateErrorMessage("");
      return;
    }

    const maxDaysForPlan =
      getPlanDetailsFromString(userInfo?.data.plantype)?.maxDays ?? 2000;

    if (!(moment(endDate).diff(moment(startDate), "days") < maxDaysForPlan)) {
      setDateErrorMessage(
        `* Date range must be less than ${maxDaysForPlan} days based on your plan`
      );
    } else {
      setDateErrorMessage("");
    }
  }, [dateRange, userInfo?.data]);

  // useEffect(() => {
  //   if (!userInfo?.data?.plantype) {
  //     setDateErrorMessage("");
  //     return;
  //   }

  //   const maxHotelsForPlan =
  //     getPlanDetailsFromString(userInfo?.data.plantype)?.maxHotels ?? 100;

  //   if (selectedResorts.length > maxHotelsForPlan) {
  //     setHotelErrorMessage(
  //       `* Number of Resorts must by less than ${maxHotelsForPlan} based on your plan`
  //     );
  //   } else {
  //     setHotelErrorMessage("");
  //   }
  // }, [selectedResorts, userInfo?.data]);

  if (isLoading || !user.isLoaded || userInfo.isLoading)
    return (
      <LoadingOverlay
        visible
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
    );

  if (!user.isSignedIn) {
    navigate("/sign-in");
  }

  if (isError) return <div>Error</div>;

  const resorts = data
    ?.map((resort: any) => resort?.hotel_name)
    .filter(
      (hotelName: string | undefined) =>
        hotelName &&
        hotelName !== "The Cabins at Disney's Fort Wilderness Resort"
    );

  const minDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  };

  const maxDate = () => {
    const year = new Date().getFullYear() + 1;
    const octoberFirst = new Date(year, 9, 1);
    return octoberFirst;
  };

  const numberOfQueriesLeftString = () => {
    if (userInfo?.data.availablenumberofqueries === 1) {
      return "1 Query Left";
    }
    return `${userInfo?.data.availablenumberofqueries} Queries Left`;
  };

  return (
    <AppShell>
      <Container size="md">
        <Styled.TitleContainer>
          <Title order={2}> Create New Hotel Search</Title>
        </Styled.TitleContainer>

        <Styled.Alert>
          <Alert
            variant="light"
            radius="lg"
            color="blue"
            title={numberOfQueriesLeftString()}
            icon={<CiCircleInfo />}
          ></Alert>
        </Styled.Alert>

        <Space h="md" />

        {(dateErrorMessage || hotelErrorMessage) && (
          <Styled.ErrorAlert>
            <Alert
              variant="light"
              radius="lg"
              color="red"
              title={"Invalid Query"}
              icon={<MdError />}
            >
              {dateErrorMessage}
              <Space h="md" />
              {hotelErrorMessage}
            </Alert>
          </Styled.ErrorAlert>
        )}

        <Space h="md" />

        <Styled.Container>
          <Flex gap="md" direction="row">
            <DatePickerInput
              withAsterisk
              type="range"
              label="Pick A Date Range You Want To Travel"
              placeholder="No Dates Selected Yet"
              value={dateRange}
              onChange={setDateRange}
              minDate={minDate()}
              maxDate={maxDate()}
            />
            <NumberInput
              withAsterisk
              label="Number Of Days In Resort"
              min={2}
              max={14}
              value={numberOfDays}
              onChange={setNumberOfDays}
            />
          </Flex>
          <Styled.ResortInput>
            {resorts.length > 0 && (
              <>
                <Styled.ResortInputPicker>
                  <MultiSelect
                    label="Pick Your Resorts"
                    clearable
                    searchable
                    placeholder=""
                    data={resorts}
                    value={selectedResorts}
                    onChange={setSelectedResorts}
                  />
                </Styled.ResortInputPicker>
                <Styled.AllResortsButton>
                  <Button onClick={() => setSelectedResorts(resorts)}>
                    Select All Resorts
                  </Button>
                </Styled.AllResortsButton>
              </>
            )}
          </Styled.ResortInput>
          <Flex gap="md" direction="row">
            <NumberInput
              withAsterisk
              label="Number Of Adults"
              min={1}
              max={9}
              value={numberOfAdults}
              onChange={setNumberOfAdults}
            />
            <NumberInput
              withAsterisk
              label="Number Of Children"
              min={0}
              max={6}
              value={numberOfChildren}
              onChange={setNumberOfChildren}
            />
          </Flex>
          <Flex gap="md" direction="row">
            {Array.from(
              { length: parseInt(numberOfChildren + "") ?? 0 },
              (_, index) => (
                <NumberInput
                  key={index}
                  withAsterisk
                  label={`Age of Child ${index + 1}`}
                  min={1}
                  max={17}
                  value={kidAges[index]}
                  onChange={(value: any) => {
                    const updatedKidsAges = kidAges;
                    updatedKidsAges[index] = value;
                    setKidAges(updatedKidsAges);
                  }}
                />
              )
            )}
          </Flex>
        </Styled.Container>
      </Container>

      <AppShell.Footer p="md">
        <Flex justify="flex-end">
          <Button
            variant="filled"
            disabled={
              dateRange[0] === null ||
              dateRange[1] === null ||
              selectedResorts.length === 0 ||
              dateErrorMessage.length > 1 ||
              hotelErrorMessage.length > 1 ||
              userInfo?.data.availablenumberofqueries === 0
            }
            onClick={() => {
              mutation.mutate();
            }}
          >
            Submit
          </Button>
        </Flex>
      </AppShell.Footer>
    </AppShell>
  );
}
