import styled from "@emotion/styled";
import { InitialBlock } from "./InitialBlock";
import { FourBullets } from "./FourBullets";
import { Space } from "@mantine/core";
import { Story } from "./Story";
import { Final } from "./Final";
import { Footer } from "./Footer";
import { Headline } from "./Headline";
import { HowItWorks } from "./HowItWorks";
import { BlackCallout } from "./BlackCallout";
import { FinalCTA } from "./FinalCta";
import { FancyFooter } from "./FancyFooter";

const Styled = {
  Background: styled.div({
    minHeight: "100vh",
    fontFamily: "'Montserrat', sans-serif",
    paddingTop: 60,
  }),
  Main: styled.div({}),
};

export function Home() {
  return (
    <Styled.Background>
      <Styled.Main>
        <Headline />
        <BlackCallout />
        <HowItWorks />
        <FinalCTA />
        <FancyFooter />
        {/* <Space h={1000} /> */}
        {/* <InitialBlock />
        <FourBullets />
        <Story />
        <Final />
        <Footer /> */}
      </Styled.Main>
    </Styled.Background>
  );
}
