/** @jsxImportSource @emotion/react */
import { useUser } from "@clerk/clerk-react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Image } from "@mantine/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Styled = {
  Headline: styled.div({
    fontFamily: '"Inter"',
    fontSize: "60px",
    fontStyle: "normal",
    fontWeight: "900",
    letterSpacing: "-.02em",
    color: "rgba(255, 255, 255, 0.9)",
    textShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    marginTop: 150,
    marginBottom: 30,
    lineHeight: 1,
  }),
  HeadlineSubText: styled.p({
    marginTop: 0,
    fontFamily: '"Inter", "sans-serif"',
    fontSize: "18px",
    fontStyle: "normal",
    color: "rgba(255, 255, 255, 0.9)",
    textShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    maxWidth: 600,
    textAlign: "center",
    margin: "0 auto",
    marginBottom: 40,
  }),

  ButtonContainer: styled.div({
    display: "flex",
    justifyContent: "center",
    gap: 16,
  }),

  Gradient: styled.div({
    width: "100vw",
    minHeight: "500px",
    background: "linear-gradient(#1e3a8a, #3b82f6, #60a5fa 60%)",
    overflow: "hidden",
    position: "relative",
    marginTop: "200px",

    "@media (max-width: 768px)": {
      minHeight: "700px",
    },
    "@media (max-width: 480px)": {
      minHeight: "700px",
    },
  }),
};

export const FinalCTA = () => {
  const navigate = useNavigate();
  const user = useUser();

  return (
    <Styled.Gradient>
      <Styled.Headline>✌🏻 Up and running in 3 minutes</Styled.Headline>
      <Styled.HeadlineSubText>
        Select your desired travel window, select interested resorts, and let
        Magical Date Finder find you the cheapest days for your trip!
      </Styled.HeadlineSubText>
      <Styled.ButtonContainer>
        <Button
          color="rgba(255, 255, 255, 1)"
          styles={{
            label: { color: "black" },
            inner: { fontSize: 20 },
          }}
          onClick={() =>
            user.isSignedIn ? navigate("/newHotelQuery") : navigate("/sign-up")
          }
          variant="filled"
          size="lg"
          radius="md"
        >
          Get Started
        </Button>
        <Button
          color="rgba(0, 0, 0, 0.2)"
          styles={{
            label: { color: "white" },
            inner: { fontSize: 20 },
          }}
          variant="filled"
          size="lg"
          radius="md"
          onClick={() => navigate("/help")}
        >
          Contact Us
        </Button>
      </Styled.ButtonContainer>
    </Styled.Gradient>
  );
};
