// Step 1: Enum for plan types
enum PlanType {
  Free = "free",
  Starter = "starter",
  Enthusiast = "enthusiast",
  Enterprise = "enterprise",
}

// Step 2: Define an interface for the plan details
interface PlanDetails {
  maxHotels: number;
  maxDays: number;
}

// Step 3: Object to map plans to their details
const PlanInfo: Record<PlanType, PlanDetails> = {
  [PlanType.Free]: { maxHotels: 1, maxDays: 30 },
  [PlanType.Starter]: { maxHotels: 3, maxDays: 60 },
  [PlanType.Enthusiast]: { maxHotels: 10, maxDays: 120 },
  [PlanType.Enterprise]: { maxHotels: 200, maxDays: 180 },
};

// Step 4: Function to validate if a string is a valid PlanType
function isPlanType(value: string): value is PlanType {
  return Object.values(PlanType).includes(value as PlanType);
}

// Step 5: Function to get plan details using string input
export function getPlanDetailsFromString(
  planTypeStr: string
): PlanDetails | null {
  if (isPlanType(planTypeStr)) {
    return PlanInfo[planTypeStr as PlanType];
  }
  console.error("Invalid plan type");
  return null; // Return null or handle the error case as appropriate
}
